import React, { FC, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import moment from 'moment';
import 'moment/locale/ru';

import { Arrow2Icon } from "../../assets/icons";
import { useDispatch, useSelector } from "react-redux";
import { BookingSelectors, closeChessModalWindow } from "../../redux/reducers/bookingSlice";

import styles from "./CheckerboardTable.module.scss";
import { MainObjectShort } from "../../redux/types/bookingTypes";
import useWindowDimensions from "../../utils/useWindowDimensions";
import CheckerboardCell from "../CheckerboardCell";
import MobileChessBanner from "../CheckerboardBanner/MobileChessBanner";
import { useClickOutside } from "../../utils/functions";
import Loader from "../Loader";

type ObjectState = {
  id:number,
  value:boolean
}
type CheckerboardTableProps= {
  period: moment.Moment[],
  today: moment.Moment,
  initialDay: moment.Moment,
  isForceUpdate:boolean,
  setForceUpdate:React.Dispatch<React.SetStateAction<boolean>>,
  objects:MainObjectShort[],
  selectedDate:Date,
  setSelectedDate:React.Dispatch<React.SetStateAction<Date>>,
  handlerToday:() => void
}

const CheckerboardTable:FC<CheckerboardTableProps> = ({
  period, 
  today, 
  initialDay,
  isForceUpdate, 
  setForceUpdate,
  objects,
  selectedDate,
  setSelectedDate,
  handlerToday
}) => {
  const dispatch = useDispatch();
  const menuRef = useRef(null);
  const btnRef = useRef(null);

  useClickOutside(menuRef, btnRef, () => dispatch(closeChessModalWindow()));  

  const bookingArray = useSelector(BookingSelectors.getBookingList);
  const chessModalWindow = useSelector(BookingSelectors.getChessModalWindow)

  const { width } = useWindowDimensions();
  const [asideWidth, setAsideWidth] = useState(0);
  const [itemWidth, setItemWidth] = useState<number>(0);   // Объявляем ширину ячейки таблицы, используется в расчетах и стилях

  const totalTableWidth = period.length*itemWidth+asideWidth  //общая ширина окна таблицы для отображения скролла

  const [scrollPosition, setScrollPosition] = useState<number>(itemWidth*180);
  
  // useEffect(()=>{          //  изменяем ширину ячейки таблицы в зависимости от ширины окна
  //   if(width>1048 && itemWidth!==100){
  //     setItemWidth(100)
  //     handlerToday()  //  Возвращаем скролл к текущей дате
  //   } else if(width>600 && width<=1048 && itemWidth!==64){
  //     setItemWidth(64)
  //     handlerToday()
  //   } else if(width<=600 && itemWidth!==36){ 
  //     setItemWidth(36)
  //     handlerToday()
  //   }
  // }, [width])
  
  useEffect(()=>{    //  изменяем ширину бокового столбца таблицы в зависимости от ширины окна
    if(width>1200 && (asideWidth!==250 || itemWidth!==100)){
      setAsideWidth(250);
      setItemWidth(100);
      handlerToday() //  Возвращаем скролл к текущей дате
    } else if(width>1048 && width<=1200 && (itemWidth!==100 || asideWidth!==190)){
        setItemWidth(100)
        setAsideWidth(190) 
        handlerToday()
    } else if(width>932 && width<=1048 && ( asideWidth!==190 || itemWidth!==64)){
        setAsideWidth(190) 
        setItemWidth(64)
        handlerToday()     
    } else if(width>600 && width<=932 && (asideWidth!==160 || itemWidth!==64)){
      setAsideWidth(160)
      setItemWidth(64)
      handlerToday()
    } else if(width<=600 && (width!==95 || itemWidth!==36)) {
      setAsideWidth(95);
      setItemWidth(36)
      handlerToday()     
    }
  },[width])

  // useEffect(()=>{    //  изменяем ширину бокового столбца таблицы в зависимости от ширины окна
  //   if(width>1200&& asideWidth!==250){
  //     setAsideWidth(250);
  //     handlerToday() //  Возвращаем скролл к текущей дате
  //   } else if(width>932 && width<=1200 && asideWidth!==190){
  //     setAsideWidth(190) 
  //     handlerToday()     
  //   } else if(width>600 && width<=932 && asideWidth!==160){
  //     setAsideWidth(160)
  //     handlerToday()
  //   } else if(width<=600 && width!==95) {
  //     setAsideWidth(95);
  //     handlerToday()     
  //   }
  // },[width])

  const containerWidth = width-150;   //размер окна просмотра
  const overscan = 5;       // рендер "запасных" ячеек при виртуальном скролле
  
  const updateScrollForDate = (date:moment.Moment) => {          //обновление скролла после выбора дат на календаре
    const indexDate = period.findIndex((item)=>item.startOf('day').unix()===date.startOf('day').unix())
    const scroll = (indexDate*itemWidth) - itemWidth*2
    setScrollPosition(scroll)
  }
  useEffect(()=>{
    updateScrollForDate(initialDay)
    setSelectedDate(initialDay.toDate())
  },[initialDay, itemWidth])  

  const [scrollLeft, setScrollLeft] = useState(scrollPosition);
  const scrollElementRef = useRef<HTMLDivElement>(null);
  
  useEffect(()=>{
    if(scrollElementRef.current!.scrollLeft!==scrollPosition){
      scrollElementRef.current!.scrollLeft=scrollPosition; 
    }
  },[scrollPosition])

  useEffect(()=>{
    if(isForceUpdate){
      scrollElementRef.current!.scrollLeft=scrollPosition; 
    }
  },[isForceUpdate])

  useLayoutEffect(()=>{   //   отслеживаем состояние скролла
    const scrollElement = scrollElementRef.current

    if(!scrollElement){
      return
    }

    const handleScroll = () =>{
      const scrollLeft = scrollElement.scrollLeft;
      setScrollLeft(scrollLeft);
      setForceUpdate(false);
    }

    handleScroll();

    scrollElement.addEventListener('scroll', handleScroll);

    return () => scrollElement.removeEventListener('scroll', handleScroll);

  },[])
  
  const virtualItems = useMemo(()=> {       // определяем какие элементы будут отрендерены для окна видимости (виртуальный скролл)
    const rangeStart = scrollLeft;
    const rangeEnd = scrollLeft + containerWidth;

    let startIndex = Math.floor(rangeStart / itemWidth);
    let endIndex = Math.ceil(rangeEnd / itemWidth);

    startIndex = Math.max(0, startIndex - overscan);
    endIndex = Math.min(period.length-1, endIndex + overscan);
    
    const virtualItems =[]
    for (let index=startIndex; index<=endIndex; index++){
      virtualItems.push({
        index:index,
        offsetLeft:index * itemWidth + asideWidth - 2
      })
    }
    
    return virtualItems

  },[scrollLeft, period.length, itemWidth, asideWidth])  
  
  const [openObject, setOpenObject] = useState<ObjectState[]>([]); //state для боковой части таблицы: какие элементы закрыты или открыты

  useEffect(()=>{
    objects&&objects.forEach((item)=>{
      const objId = item.id;
      setOpenObject(state =>[...state,{id:objId, value:true}]) //начальное положение таблицы: все объекты раскрыты      
    })
  },[])
//------------------------  
  const handlerClick =(id:number) =>{  //обрабатываем смену state таблицы: раскрыты или скрыты объекты 
    const current=openObject.findIndex(item=> item.id===id)
    
    if(current>-1){
     setOpenObject(prevState=> {
      const newObjects = prevState.map(item=>{return item})      
      newObjects[current].value=!newObjects[current].value     
      return(
        newObjects
      )
     })
    }    
  }
//----------------------
  const aside=objects&&objects.map((item)=>{   // боковое меню таблицы
    const innerObject =item.rental_objects.length>0&& item.rental_objects.map((obj)=> { //вложенные в основной объект сдаваемые объекты
      return(
        <div className={styles.object} key={`R${obj.id}`}>
         <div className={styles.obj_name}>{obj.name}</div>          
        </div>      
      )
    })
    const current = openObject.find(itemValue=> itemValue.id===item.id)
    const display = current?.value||false   // находим state для данного основного объекта
    return(
      <React.Fragment key={item.id}>
        <div className={classNames(styles.object, styles.header)} >
          <div className={styles.obj_name}>{item.name}</div>
          <span className={classNames(styles.arrow, display&&styles.open)} onClick={()=>handlerClick(item.id)}><Arrow2Icon/></span>
        </div>   
        {display&&innerObject}    
      </React.Fragment>   
    )
  })  
//-----------------  
  const filterBookingWithStatus = bookingArray&&bookingArray.filter(item=> item.status==="cfm"||item.status==="awt")

  const startData = virtualItems.length>0?period[virtualItems[0].index]:period[170] // первая дата периода /// 

  const longBooking = filterBookingWithStatus&&filterBookingWithStatus.filter(item=> moment(item.check_in_date).startOf('day')<startData&&moment(item.check_out_date).startOf('day')>startData)
   
  const dates = virtualItems.map((virtualItem, index)=>{  // создаем массив ДОМ-элементов для рендеринга столбцов с датами (виртуальный скролл)
    const item = period[virtualItem.index]
    const isToday = item.dayOfYear()===today.dayOfYear() ? true: false    
    const isSelected = moment(selectedDate).startOf('day').isSame(item.startOf('day')) ? true : false
    const isWeekend = (item.day()===0||item.day()===6) ? true : false
    const filterBookingForDay = filterBookingWithStatus&&filterBookingWithStatus.filter(item=> moment(item.check_in_date).startOf('day').isSame(period[virtualItem.index].startOf('day')))    
    
    return(
      <div key={item.unix()} className={classNames(styles.column,{
        [styles.selectDate]:isSelected,           
        [styles.today]:isToday
        }
      )}   //рисуем столбец для даты
        style={{
          width:itemWidth,
          position:'absolute',
          left:virtualItem.offsetLeft
        }}
      >      
        <div  className={classNames(
          styles.tableGrid, 
          styles.head, {           
            [styles.weekend]:isWeekend
          }
        )} id={item.unix().toString()}
          style={{
            width:itemWidth
          }}
          >
          <div className={styles.headDate}>{item.format('D.MM.YY')}</div>  
          <div className={styles.headDateMobile}>
            <div className={styles.monthMobile}>{item.format('MMM').slice(0,3)}</div> 
            <div className={styles.yearMobile}>{item.format('YYYY')}</div>
          </div>  
          <div className={styles.dayOfWeek}> {item.format('dd')}</div>  
          <div className={styles.dayMobile}> 
            <div className={styles.dateMobile}>{item.format('DD')}</div>
            <div className={styles.weekMobile}>{item.format('dd')}</div>
          </div>  
        </div>

        {objects&&objects.map((object)=>{
          const current = openObject.find(itemValue=> itemValue.id===object.id)
          const display = current?.value||false     
          const id = `obj${object.id}_day${item.startOf('day').unix()}`
          
          const innerObject = object.rental_objects.map((obj)=> {
            const filterBookingForObject = filterBookingForDay&&filterBookingForDay.find(item=> item.rental_object===obj.id) 
            const filterLongBookingForObject = (index===0) ? longBooking&&longBooking.find(item=> item.rental_object===obj.id) : null
            return(
              <React.Fragment key={`RO${obj.id}${item.unix()}`}>
                <CheckerboardCell
                  itemWidth={itemWidth}
                  id={obj.id}
                  booking={filterBookingForObject}
                  longBooking ={filterLongBookingForObject}
                  index={index}
                  day={item}
                  isToday = {isToday}
                  isSelected = {isSelected}
                  isWeekend = {isWeekend}
                />  
              </React.Fragment>         
            )
          })        
          return(
            <React.Fragment key={object.id}>
              <CheckerboardCell
                  itemWidth={itemWidth}
                  id={id}
                  index={index}
                  day={item}
                  isToday = {isToday}
                  isSelected = {isSelected}
                  isWeekend = {isWeekend}
                />  
            {/* <div className={classNames(styles.tableGrid,  (item.day()===0||item.day()===6)&&styles.weekendGrid)} 
              style={{width:itemWidth}} id={id}
            >              
            </div> */}
            {display&&innerObject}
            </React.Fragment>
          )
        })}
      </div>
    )})     
   
  return (
    <div className={styles.container}>             
      <div className={styles.tableWrapper} ref={scrollElementRef} >
        
        {scrollPosition!==0 && itemWidth!==0 && <div style={{width:totalTableWidth}} className={styles.table}>
          <div style={{width:asideWidth}} className={styles.aside}>
            <div style={{width:asideWidth}} className={styles.tableHeadNotation}>
                <div className={styles.headRight}>Дата </div>
                <div className={styles.headLeft}>Объект</div>
            </div>
            {aside}
          </div> 
        {dates}                
        </div>}
      </div>
      {chessModalWindow.isOpen&&chessModalWindow.booking&&
        <div className={styles.modal} ref={menuRef}>
          <MobileChessBanner
            booking={chessModalWindow.booking}
            bookingClassName={chessModalWindow.bookingClassName}
            btnRef={btnRef}
          />
        </div>
      }
    </div>
  ) 
}

export default CheckerboardTable;